<template>
  <div class="editServiceTable" :style="{ height: tableContainerHeight }">
    <el-table
      ref="table"
      :class="{ show_empty: showEmpty }"
      v-loading="tableLoading"
      :data="serviceTableData"
      v-on="$listeners"
      @row-click="rowClick"
      highlight-current-row
      :row-class-name="tableRowClassName"
      empty-text=" "
      :max-height="tableHeight"
    >
      <el-table-column prop="dataIndex" :label="$t('ResourceManagement.customer.sequenceNumber')" v-if="isIndex">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in chooseInputArr"
        :key="index + 50"
        :align="item.align || 'left'"
        :min-width="item.width ? item.width : '150'"
      >
        <template #header>
          <el-tooltip effect="dark" :content="$t('ResourceManagement.' + applyComp + '.' + item.prop)" placement="top-start">
            <span class="headerClass" style="padding-right: 5px">{{ $t('ResourceManagement.' + applyComp + '.' + item.prop) }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <div v-if="!choosedThisRow(scope.$index, item.prop, item.editable)">
            <span v-if="translateString(scope.row[item.prop])">
              <!-- {{ Array.isArray(scope.row[item.prop]) ? scope.row[item.prop].toString() : $t(filterColumn(item.prop, scope.row[item.prop])) }} -->
              {{ getShowText(item.prop, scope.row[item.prop]) }}
            </span>
            <el-popover
              v-else
              placement="top"
              width="300"
              trigger="hover"
              :content="getShowText(item.prop, scope.row[item.prop])"
              popper-class="poppers"
            >
              <p slot="reference" class="overToolTips">
                <!-- {{ Array.isArray(scope.row[item.prop]) ? scope.row[item.prop].toString() : $t(filterColumn(item.prop, scope.row[item.prop])) }} -->
                {{ getShowText(item.prop, scope.row[item.prop]) }}
              </p>
            </el-popover>
          </div>

          <el-input
            size="mini"
            v-else-if="authGroup.update"
            class="hours-input"
            v-model="scope.row[item.prop]"
            @change="recordProps(scope.row)"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column fixed="right" align="center" min-width="140">
        <template #header>
          <span>{{ $t('commonVariables.operate') }}</span>
        </template>
        <template slot-scope="scope">
          <div v-if="shrinkage" class="operations">
            <span v-if="authGroup.detail" class="detail" @click="checkDetail(scope.row)">{{ $t('commonVariables.details') }}</span>
            <!-- click事件必须包含在blur的聚焦区域之内才会顺利生效，但是不会触发@blur绑定的事件，blur事件的优先级高于click，导致click触发的事件失效，所以用Mousedown -->
            <div :class="['pullMore', { pullMoreCheck: isClick }]" tabindex="0" @focus="isShow(1, scope.row)" @blur="isShow(0, scope.row)">
              <span class="text">...</span>
            </div>
            <div
              :class="['selectData', { firstRowTips: scope.$index == 0 }, { oneRowTips: serviceTableData.length == 1 }]"
              v-show="serviceId === scope.row.id"
            >
              <ul v-for="ids in operationItems" :key="ids" class="selectUl">
                <li class="selectLi" @mousedown.stop="operationType(ids, scope.$index, serviceTableData)">
                  {{ $t(ids) }}
                </li>
              </ul>
            </div>
          </div>
          <div v-else class="operations" fixed="right">
            <!-- <span v-if="readPermission" @click="checkRow(scope.$index, serviceTableData)" class="editRow">查看</span> -->
            <span v-if="editDiago && authGroup.update" @click="editRow(scope.$index, serviceTableData)" class="editRow">
              {{ $t('commonVariables.edit') }}
            </span>
            <span v-if="authGroup.del" @click="deleteRow(scope.$index, serviceTableData)" class="deleteRow">{{ $t('commonVariables.delete') }}</span>
            <span v-if="!visitorAuth">{{ $t('commonVariables.noPermission') }}</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      ref="pagination"
      class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 30, 50, 100]"
      :page-size="pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  computed: {
    tableContainerHeight() {
      return this.tableHeight + 65 + 'px';
    },
    routeName() {
      return this.$route.meta.name;
    },
    translateString() {
      return val => {
        return val?.toString()?.length < 20;
      };
    },
    visitorAuth() {
      return Object.values(this.authGroup).find(val => val);
    },
  },
  props: {
    page: Number,
    pagesize: Number,
    count: Number,
    isIndex: {
      type: Boolean,
      default: true,
    },
    // 对应必填项的项目数组
    requiredIndex: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 必须唯一的的项目数组
    repeatFiled: {
      type: Array,
      default: () => {
        return [];
      },
    },
    bdVisable: {
      type: Boolean,
      default: false,
    },
    editDiago: {
      type: Boolean,
      default: false,
    },
    reportType: {
      type: String,
      default: '',
    },
    // 复制行
    copyRow: {
      type: Boolean,
      default: false,
    },
    // 操作栏省略
    shrinkage: {
      type: Boolean,
      default: false,
    },
    authGroup: {
      type: Object,
      default: () => {
        return {};
      },
    },
    saveKey: Boolean,
    applyComp: String,
    tableHeight: {
      type: Number,
      default: 0,
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
    updatePermission: {
      type: Boolean,
      default: false,
    },
    serviceTableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    chooseInputArr: {
      type: Array,
      default: () => {
        return [];
      },
    },
    deleteTips: {
      type: String,
      default: '此操作将删除该服务项, 是否继续?',
    },
  },
  data() {
    return {
      id: '',
      showEmpty: false,
      isClick: false,
      serviceId: false,
      operationItems: [],
      currentRoute: '',
    };
  },

  watch: {
    serviceTableData: {
      deep: true,
      immediate: false,
      handler(newVal) {
        !newVal.length && (this.showEmpty = true);
        if (newVal.length) {
          this.operationItems = [];
          if (this.authGroup['update']) this.operationItems.push('commonVariables.edit');
          if (this.authGroup['del']) this.operationItems.push('commonVariables.delete');
          if (this.authGroup['follow']) this.operationItems.push('ResourceManagement.customer.follow');
        }
      },
    },

    saveKey(newVal) {
      if (newVal) {
        this.id = '';
      }
    },
  },

  methods: {
    checkFollow(row) {
      this.$emit('checkFollow', row);
    },
    checkDetail(row) {
      this.$emit('checkDetail', row);
    },
    operationType(val, index, tableData) {
      switch (val) {
        case 'commonVariables.delete':
          this.deleteRow(index, this.serviceTableData);
          break;
        case 'commonVariables.edit':
          // this.$emit('editServiceItem', tableData[index]);
          tableData[index].bdIds ? null : (tableData[index].bdIds = []);
          this.$emit('editServiceItem', tableData[index]);
          break;
        case 'ResourceManagement.customer.follow':
          this.checkFollow(tableData[index]);
          break;
      }
    },
    isShow(bool, row) {
      if (bool) {
        this.isClick = true;
        this.serviceId = row.id;
      } else {
        this.isClick = false;
        this.serviceId = '';
      }
    },
    handleSizeChange(val) {
      this.$emit('pageChange', 'size', val);
    },
    handleCurrentChange(val) {
      this.$emit('pageChange', 'current', val);
    },
    /*     headerCellMeshStyle(cell) {
            const isCheck = cell.row.find(item => {
        return item.label === '操作';
      });
      if (isCheck) {
        return 'fixed-cell';
      } 
  
    }, */
    recordProps(item) {
      this.$emit('record', item);
    },

    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
      if (this.requiredIndex.includes(rowIndex) || this.repeatFiled.includes(rowIndex)) {
        return 'warning-row';
      }
    },

    async checkRow(index, rows) {
      this.$emit('readServiceItem', rows[index]);
    },
    async editRow(index, rows) {
      this.$emit('editServiceItem', rows[index]);
    },
    async deleteRow(index, rows) {
      this.$confirm(this.deleteTips, this.$t('commonVariables.notice'), {
        confirmButtonText: this.$t('commonVariables.complete'),
        cancelButtonText: this.$t('commonVariables.cancel'),
        type: 'warning',
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          const delRow = rows[index];
          // rows.splice(index, 1);
          if (delRow.id) {
            this.$emit('deleteData', delRow.id);
            this.$message({
              type: 'success',
              message: '已删除',
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除！',
          });
        });
    },
    indexMethod(index) {
      return index + 1 < 10 ? '0' + (index + 1) : index + 1;
    },
    //记录点击了哪一行
    rowClick(row) {
      this.$emit('update:saveKey', false);
      this.id = row.id;
    },
    /* 把选中的行的某些单元格替换为输入框 */
    choosedThisRow(idx, key, edit) {
      if (this.routeName !== '申办方维护') {
        return;
      }
      if (this.editDiago || (!this.updatePermission && !this.serviceTableData[idx].type)) {
        return;
      }

      // 编辑情况下直接编辑
      if (this.serviceTableData[idx].id) {
        return this.id === this.serviceTableData[idx].id && edit;
      }
      // 新建项目情况下
      else {
        return edit && this.serviceTableData[idx];
      }
    },
    // 获取展示文本
    getShowText(prop, data) {
      if (Array.isArray(data)) {
        if (prop === 'types') {
          let result = [];
          data.forEach(item => {
            result.push(this.$t(this.filterColumn(item)));
          });
          return result.join(',');
        }
        return data.toString();
      }
      return data;
    },
    filterColumn(item) {
      switch (item) {
        case 'other':
          return 'ResourceManagement.customer.else';
        case 'business':
          return 'ResourceManagement.customer.businessContact';
        case 'purchase':
          return 'ResourceManagement.customer.procurementContact';
        case 'accountant':
          return 'ResourceManagement.customer.accountant';
        case 'dm':
          return 'ResourceManagement.customer.dm';
        case 'stat':
          return 'ResourceManagement.customer.stat';
        case 'pk':
          return 'ResourceManagement.customer.pk';
        case 'med':
          return 'ResourceManagement.customer.med';
        case 'co':
          return 'ResourceManagement.customer.co';
        default:
          return '';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/compsStyle/projectStatement.scss';
.headerClass {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .el-table__row {
  .el-table__cell {
    padding-top: 12px;
    padding-bottom: 12px;
    .overToolTips {
      width: 140px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .hours-input {
      font-size: 14px;
      .el-input__inner {
        color: #515a6e;
        border: none;
        text-align: left;
        height: 29px;
        line-height: 29px;
        background-color: #fff;
      }
    }
    .start::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      width: 8px;
      height: 8px;
      background: #15ad31;
      border-radius: 50%;
    }
    .unStart::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      width: 8px;
      height: 8px;
      background: #ff9810;
      border-radius: 50%;
    }
  }
}
.editServiceTable {
  padding: 12px 32px;
  padding-bottom: 0 !important;
  margin-top: 16px;
  background: #fff;
  overflow: hidden;
  border-radius: 2px;
  ::v-deep .el-table__fixed-right {
    background-color: #fff;
    font-size: 14px;
    height: 100% !important; //设置高优先，以覆盖内联样式
  }
  ::v-deep .el-table__fixed {
    background-color: #fff;
    font-size: 14px;
    height: 100% !important; //设置高优先，以覆盖内联样式
  }
  ::v-deep .el-table__header {
    th:not(.is-hidden):nth-child(n-2) {
      color: #515a6e;
      background: #f8f8f9;
      .cell {
        padding-bottom: 1px;
      }
    }
    /*     th:not(.is-hidden):last-child::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      color: #DCDEE2;
      background: #DCDEE2;
      left: 0;
      bottom: 6px;
    } */
  }

  .column-pointer {
    cursor: default;
  }
  .operations {
    cursor: pointer;
    span {
      position: relative;
      display: inline-block;
      padding: 0 8.5px;
      // width: 126px;
      // height: 20px;
      color: #2d8cf0;
    }
    .pullMore {
      vertical-align: middle;
      display: inline-block;
      text-align: center;
      width: 16px;
      height: 16px;
      background: #e7f1ff;
      border-radius: 50%;
      .text {
        display: inline-block;
        padding: 0;
        transform: translateY(-30%);
      }
    }
    .pullMoreCheck {
      background: #e3eefd;
    }
    .selectData {
      position: absolute;
      z-index: 999999;
      border-radius: 4px;
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
      border: 1px solid #e4e7ed;
      bottom: 20px;
      width: 70px;
      right: 0;
      .selectUl {
        .selectLi {
          padding: 1px 0;
          font-size: 13px;
          border-radius: 4px;
          color: #2d8cf0;
          background: #fff;
          &:hover {
            background: #f8f8f9;
          }
        }
      }
      .selectUl:first-child {
        .selectLi {
          padding-top: 6px;
        }
      }
      .selectUl:last-child {
        .selectLi {
          padding-bottom: 6px;
        }
      }
    }
    .firstRowTips {
      bottom: 0;
      top: 20px;
    }
    .oneRowTips {
      background: #fff;
      display: flex;
      flex: 1;
      justify-content: space-around;
      align-items: center;
      width: 100px;
      height: 30px;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      .selectLi {
        flex: 1;
        min-width: 20px;
        padding: 0 !important;
      }
    }
    .editRow::after {
      content: '';
      position: absolute;
      right: 0px;
      top: 4px;
      width: 1px;
      height: 14px;
      background: #e9e9e9;
    }
    /*     .deleteRow::before {
      content: '';
      position: absolute;
      left: 0px;
      top: 4px;
      width: 1px;
      height: 14px;
      background: #e9e9e9;
    } */
  }
  @import url('@styles/element-ui.scss');
}
::v-deep .el-input__inner {
  color: #515a6e;
  height: 28px;
  line-height: 28px;
}
@include tableClass;
::v-deep.show_empty .el-table__empty-text {
  display: initial;
}
</style>

import { BaseService } from '@/api/request';
const api = {};
// NaN undefined null过滤处理
// 注意，尽量不要过滤空字符串
const filterNull = json => {
  let newJson = {};
  let reg = RegExp(/NaN|undefined|null/);
  for (let li in json) {
    if (json[li] && !reg.test(json[li])) {
      newJson[li] = json[li];
    }
  }
  return newJson;
};

/**
 * @description: 获取资源管理的数据
 */
// 申办方
api.sponsorData = function (params = {}) {
  return BaseService.getRequest('/api/pmf/v1/resource/sponsors', filterNull(params));
};
api.sponsorSaveData = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/resource/sponsors', filterNull(params));
};
api.sponsorDeleteData = function (params) {
  return BaseService.delRequest('api/pmf/v1/resource/sponsors/' + params);
};
// 客户
api.customersData = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/resource/customers', filterNull(params));
};
api.customersAddData = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/resource/customers', filterNull(params));
};
api.customersEditData = function (params = {}, id) {
  return BaseService.patchRequest('api/pmf/v1/resource/customers/' + id, filterNull(params));
};
api.updateCustomersAddress = function (params = {}, id) {
  return BaseService.patchRequest('api/pmf/v1/resource/customers/' + id + '/address', filterNull(params));
};
api.customersDeleteData = function (params) {
  return BaseService.delRequest('api/pmf/v1/resource/customers/' + params);
};
api.getBdData = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/resource/customers/bds', filterNull(params));
};
/* 跟进记录 */
api.getRecordList = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/resource/customerTrackings', filterNull(params));
};
api.getCurrentRecord = function (params = {}, id) {
  return BaseService.getRequest('api/pmf/v1/resource/customerTrackings/' + id, filterNull(params));
};
api.createRecord = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/resource/customerTrackings', filterNull(params));
};
api.delRecord = function (id) {
  return BaseService.delRequest('api/pmf/v1/resource/customerTrackings/' + id);
};
api.updateRecord = function (params = {}, id) {
  return BaseService.putRequest('api/pmf/v1/resource/customerTrackings/' + id, filterNull(params));
};
api.uploadRecord = function (params = {}, showMsg = false) {
  return BaseService.postRequest('api/pmf/v1/resource/customerTrackings/import', params, { showMsg });
};
api.downloadRecord = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/resource/customerTrackings/importTemplate', filterNull(params));
};
/* 客户联系人 */
api.getContactList = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/resource/customerContacts', filterNull(params));
};
api.getCurrentContactPerson = function (params = {}, id) {
  return BaseService.getRequest('api/pmf/v1/resource/customerContacts/' + id, filterNull(params));
};
api.createContactPerson = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/resource/customerContacts', filterNull(params));
};
api.updateContactPerson = function (params = {}, id) {
  return BaseService.putRequest('api/pmf/v1/resource/customerContacts/' + id, filterNull(params));
};
api.deleteContactPerson = function (id) {
  return BaseService.delRequest('api/pmf/v1/resource/customerContacts/' + id);
};
// 方案
api.phasesData = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/resource/phases', filterNull(params));
};
api.indicationsData = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/resource/indications', filterNull(params));
};
api.therapeuticsData = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/resource/therapeutics', filterNull(params));
};
api.protocolsData = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/resource/protocols', filterNull(params));
};
api.addProtocolsData = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/resource/protocols', filterNull(params));
};
api.editProtocolsData = function (id, params = {}) {
  return BaseService.putRequest('api/pmf/v1/resource/protocols/' + id, filterNull(params));
};
api.deleteProtocolsData = function (id) {
  return BaseService.delRequest('api/pmf/v1/resource/protocols/' + id);
};
api.exportStatement = function (params = {}) {
  return BaseService.getRequest('/api/pmf/v1/jobs/export', filterNull(params), {
    otherRequestConfig: { responseType: 'blob', timeout: 1000 * 60 },
  });
};
api.exportStatement = function (params = {}) {
  return BaseService.getRequest('/api/pmf/v1/jobs/export', filterNull(params), {
    otherRequestConfig: { responseType: 'blob', timeout: 1000 * 60 },
  });
};
api.exportClientData = function (params = {}, showMsg = false) {
  return BaseService.getRequest('api/pmf/v1/resource/customer/export', params, {
    otherRequestConfig: { responseType: 'blob' },
    showMsg,
  });
};
api.getSiteCountries = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/resource/siteCountries', filterNull(params));
};
// 服务项维护
api.getServiceData = function (params = {}) {
  return BaseService.getRequest('/api/pmf/v1/serviceItems', filterNull(params));
};
api.deleteServiceData = function (id) {
  return BaseService.delRequest('/api/pmf/v1/serviceItems/' + id);
};
api.addServiceData = function (params = {}) {
  return BaseService.postRequest('/api/pmf/v1/serviceItems', filterNull(params));
};
api.editServiceData = function (id, params = {}) {
  return BaseService.postRequest('/api/pmf/v1/serviceItems/' + id, filterNull(params));
};
api.getDetailServiceData = function (id) {
  return BaseService.getRequest('/api/pmf/v1/serviceItems/' + id);
};
export default api;

<template>
  <div id="filterPane">
    <div class="top-box">
      <slot name="filter"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterPane',
  props: {
    statementType: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
#filterPane {
  background-color: #fff;
  border-radius: 2px;
}
</style>

import { debounce } from '@/utils/debounce';
export const mixinsResource = {
  data() {
    return {
      otherDomHeight: 0,
      tableHeight: 0,
      tableWidth: 0,
      expandHeight: 500,
    };
  },
  computed: {
    device() {
      return this.$store.state.app.device;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTreeDom();
      this.calTableRangeWidth();
    });
    window.addEventListener('resize', this.calcTreeDom);
    window.addEventListener('resize', this.calTableRangeWidth);
  },
  activated() {
    this.$nextTick(() => {
      this.calcTreeDom();
      this.calTableRangeWidth();
    });
    window.addEventListener('resize', this.calcTreeDom);
    window.addEventListener('resize', this.calTableRangeWidth);
  },
  deactivated() {
    window.removeEventListener('resize', this.calcTreeDom);
    window.removeEventListener('resize', this.calTableRangeWidth);
  },
  methods: {
    calTableRangeWidth() {
      const siderBarWidth = this.$i18n.locale == 'en' ? 235 : 208;
      if (this.device === 'mobile') {
        this.tableWidth = '100%';
        return;
      }
      if (window.innerWidth > 1100) {
        this.tableWidth = window.innerWidth - 16 - 24 - (this.$store.getters.sidebar.opened ? siderBarWidth : 55) + 'px';
      } else {
        this.tableWidth = window.innerWidth - 16 - 12 + 'px';
      }
    },
    calcTreeDom() {
      const resizeDom = debounce(() => {
        this.otherDomHeight =
          (this.$refs.pagination?.$el.offsetHeight ?? 0) +
          (this.$refs.filterPane?.$el.offsetHeight ?? 0) +
          (this.$refs.dataForm?.$el?.offsetHeight ?? 0) +
          50 +
          32 +
          16 +
          24;
        this.tableHeight = window.innerHeight - this.otherDomHeight - 60;
      }, 10);
      resizeDom();
    },
  },
};
